<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        Employee Agreement
      </h1>
      <div class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>
    <div style="height: 100%" class="mt-20" v-if="loadingData">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div class="px-3" v-if="!loadingData">
      <card class="p-5 mt-4 flex flex-col">
        <div class="w-full flex mb-4">
          <span class="text-sm font-semibold text-romanSilver uppercase">
            Employee :
          </span>
          <span class="text-sm font-semibold ml-1">
            {{ `${employee.fname} ${employee.lname}` }}
          </span>
        </div>
        <div class="w-full flex">
          <img
            v-if="employee.photo !== '' && employee.photo !== null"
            class="mr-3 rounded"
            style="width: 135px; height: 128px"
            :src="employee.photo"
            alt="profile"
          />
          <div
            style="height: 138px; width: 138px; border-radius: 5px"
            class="text-blueCrayola border text-center font-black text-lg pt-14 mr-2"
            v-else
          >
            {{ $getInitials(`${employee.fname} ${employee.lname}`) }}
          </div>
          <div class="w-full flex flex-col">
            <div class="w-full flex">
              <card class="flex p-3 mb-4 w-auto mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-suitcase" size="xs" />
                    <span class="ml-1 text-carrotOrange text-xs">
                      Designation
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.designation }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 w-auto flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-department" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Function
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.function }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 w-auto mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="line-manager" color="#FFFFFF" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Line Manager
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{
                      employee.lineManager ? employee.lineManager.fname : "---"
                    }}
                    {{
                      employee.lineManager ? employee.lineManager.lname : "---"
                    }}
                  </div>
                </div>
              </card>
            </div>
            <div class="w-full flex">
              <card class="flex p-3 mr-3 w-auto mb-4 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon
                      icon-name="icon-user-list"
                      color="#FFFFFF"
                      size="xs"
                    />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Employee ID
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.employeeId }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="map-pin" size="xms" />
                    <span class="ml-2 text-dome text-xs">
                      Office Location
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ employee.location }}
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </card>
    </div>
    <div class="px-3" v-if="!loadingData">
      <card class="p-5" style="margin-top: 4px">
        <div
          class="w-full flex flex-row"
          style="margin-top: 20px; flex-grow: 1"
        >
          <div class="w-9/12 h-auto mt-2">
            <h1 class="text-base font-bold">Agreement Details</h1>
          </div>

          <div class="h-full w-auto flex">
            <div class="p-2 w-full flex">
              <div class="flex w-full justify-end ml-12 -mt-2">
                <Button
                  class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center p-1 text-sm"
                  width="12rem"
                  height="2rem"
                  @click="returnAgreement()"
                  v-if="!approvedAdmin"
                >
                  Return to Employee
                </Button>

                <Badge
                  label="Approved"
                  variant="primary"
                  background-color="rgba(19, 181, 106, 0.08)"
                  color="#13B56A"
                  style="
                    padding: 9px;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 1.5;
                    margin-top: -1.5%;
                  "
                  v-if="approvedAdmin"
                />

                <Button
                  class="text-center font-semibold flex p-1 text-base ml-5 -mr-10"
                  width="12rem"
                  height="2rem"
                  @click="openComments"
                >
                  <div class="flex">
                    <span class="text-flame">
                      <Icon
                        icon-name="icon-message-outline"
                        class-name="mr-2 w-5 h-5"
                        size="xsm"
                      />
                    </span>
                    <span class="mr-1">
                      {{ unreadComments }}
                    </span>
                    Comments
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div style="border-bottom: 1px solid #878e99" />

        <div class="flex flex-col w-full my-4">
          <!----- List of KPA's ---->

          <div class="w-full h-auto flex flex-col">
            <accordion
              class="mt-6"
              v-for="(kpaTypes, kpaNumber) in templateKPATypes"
              :key="kpaNumber"
              show-divider
              :is-open="true"
            >
              <template v-slot:title>
                <span class="font-bold">{{ kpaTypes.name }}</span>
              </template>

              <template v-slot:block>
                <div
                  class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                >
                  <span class="font-black text-xs mr-1">
                    {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                  </span>
                </div>
              </template>

              <div class="w-full">
                <scroll-container height="1000px">
                  <Table
                    :headers="KPAHeader"
                    :items="kpaTypes.kpas"
                    class="h-full my-2 table"
                    aria-label="absence table"
                    :has-number="false"
                  >
                    <template v-slot:item="{ item }">
                      <div v-if="item.name">
                        <div class="label">
                          <c-text
                            placeholder="--Enter KPI--"
                            variant="w-full"
                            class="mr-2 -ml-1 -mt-1 w-72"
                            v-model="item.data.name"
                            :disabled="true"
                          />
                        </div>
                      </div>

                      <div v-else-if="item.objective">
                        <div class="label">
                          <c-text
                            placeholder="--Enter Objective--"
                            variant="w-full"
                            class="mr-2 -ml-1 -mt-2 w-72"
                            v-model="item.data.objective"
                            :disabled="true"
                          />
                        </div>
                      </div>

                      <div v-else-if="item.kpis" class="flex">
                        <div
                          class="label flex"
                          v-for="(n, rating) in item.data.kpis"
                          :key="rating"
                        >
                          <c-text
                            variant="w-full"
                            :class="`mr-2 -mt-2 w-48`"
                            :placeholder="`Criteria ${rating}`"
                            v-model="item.data.kpis[rating].criteria"
                            :disabled="true"
                          />
                        </div>
                      </div>

                      <div v-else-if="item.target_date">
                        <div class="label w-72">
                          <div class="date-flex -mt-2 bg-disabled">
                            <datepicker
                              placeholder="--Select Day & Month & Year--"
                              style="width: 100%; outline: none"
                              input-class="date-input"
                              format="yyyy-MMMM-dd"
                              v-model="item.data.target_date"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </div>

                      <div v-else-if="item.weight">
                        <div class="label">
                          <c-text
                            placeholder="--Enter Weight--"
                            variant="w-full"
                            class="mr-2 -ml-1 -mt-2 w-24"
                            type="number"
                            min="0"
                            max="100"
                            v-model="item.data.weight"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </template>
                  </Table>
                </scroll-container>
              </div>
            </accordion>
          </div>
        </div>
      </card>
    </div>

    <div class="pl-3 flex mt-5" v-if="!loadingData && !approvedAdmin">
      <div class="flex flex-grow">
        <Button
          :disabled="disableBtn"
          :class="disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`"
          @click="approveAgreement()"
        >
          <span class="mr-6 ml-6">Approve</span>
        </Button>

        <Button
          background-color="none"
          class="ml-4"
          style="color: #321c3b"
          @click="$router.back()"
        >
          Cancel
        </Button>
      </div>
    </div>

    <RightSideBar
      v-if="commentModal"
      @submit="sendComment"
      @close="commentModal = false"
      submit="Save"
      button-class="bg-dynamicBackBtn text-white"
    >
      <template v-slot:title>
        <span class="text-darkPurple font-bold text-lg leading-5 p-0"
          >Comments</span
        >
      </template>
      <div class="w-full flex">
        <div class="flex flex-col w-full" style="min-height: 70vh">
          <CommentsBox
            :comments="allComments"
            @markRead="markAsRead($event)"
            :loading="loadComments"
            @deleteComment="deleteComments($event)"
          />
          <div class="-mb-12" style="margin-top: auto">
            <CTextarea
              placeholder="--Message--"
              variant="primary"
              class="mt-2"
              value=""
              :row="8"
              :height="90"
              :col="30"
              :input="null"
              v-model="comment"
            />
          </div>
        </div>
      </div>
    </RightSideBar>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Datepicker from "vuejs-datepicker";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@/components/Card";
import Button from "@/components/Button";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";

export default {
  name: "EmployeeAgreement",
  components: {
    BackButton,
    Card,
    Datepicker,
    CommentsBox,
    CTextarea,
    RightSideBar,
    CText,
    Breadcrumb,
    Table,
    Badge,
    Button,
    ScrollContainer,
    Accordion,
  },
  data() {
    return {
      loadingData: true,
      commentModal: false,
      disableBtn: false,
      agreementId: "",
      comment: "",
      approvedAdmin: false,
      allComments: [],
      unreadComments: 0,
      loadComments: true,
      employee: [],
      Ratings: [],
      templateKPATypes: [],
      kpaName: [],
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "PerformanceAgreement",
        },
        {
          disabled: false,
          text: "Employee Agreement",
          href: "employee-agreement",
          id: "EmployeeAgreement",
        },
      ],
      kpaItems: [],
      KPAHeader: [
        { title: "KPI", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
    };
  },
  methods: {
    handleCommentModal() {
      this.commentModal = !this.commentModal;
    },

    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += kpi.weight;
        });
      }

      return sum;
    },

    returnAgreement() {
      const agreementIdsArr = [];

      agreementIdsArr.push(this.agreementId);

      const payload = {
        agreementIds: agreementIdsArr,
        approvedByAdmin: false,
        approveAndSubmit: false,
      };

      this.$_returnEmployeeAgreement(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.back();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    approveAgreement() {
      const agreementIdsArr = [];

      agreementIdsArr.push(this.agreementId);

      const payload = {
        agreementIds: agreementIdsArr,
        approvedByAdmin: true,
        approveAndSubmit: true,
      };

      this.disableBtn = true;

      this.$_adminApproveAgreement(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.$router.back();
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getComments(agreementId) {
      this.allComments = [];
      this.loadComments = true;

      this.$_getAgreementComments(agreementId).then((response) => {
        const { comments } = response.data;

        const unread = [];

        this.loadComments = false;

        comments.forEach((comment) => {
          this.allComments.push({
            message: comment.comment,
            time: this.formatCommentTime(comment.date),
            date: this.formatCommentDate(comment.date),
            commentId: comment.id,
            isRead: comment.readStatus,
            receiver: comment.receiver,
            sender: comment.sender,
          });

          if (comment.readStatus === false) {
            unread.push(comment.readStatus);
          }
        });

        this.unreadComments = unread.length;
      });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();

      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },

    openComments() {
      this.commentModal = true;
    },

    async sendComment() {
      try {
        await this.$handlePrivilege("performanceAgreements", "makeComment");
        const payload = {
          performanceAgreementId: this.agreementId,
          senderId: this.$AuthUser.id,
          receiverId: this.$route.params.id,
          comment: this.comment,
          sendTo: "admin",
        };

        this.$_postAgreementComments(payload).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.agreementId);
          this.comment = "";
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    deleteComments(id) {
      this.$_deleteAgreementComments(id)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.agreementId);
        })
        .then((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };

      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.agreementId);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);
      const sortedKPIS = kpis.sort((a, b) => a.score.rating - b.score.rating);

      this.Ratings = sortedRatings;
      // eslint-disable-next-line no-param-reassign
      kpis = sortedKPIS;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
          return {};
        });
      }

      this.loadingData = false;

      return criteriaData;
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    getEmployeeAgreement() {
      this.$_viewEmployeeAgreements(this.$route.params.id).then((result) => {
        this.employee = result.data.user;
        const agreementKPA = result.data.EmployeePerformanceAgreements;

        agreementKPA.forEach((agreement) => {
          this.agreementId = agreement.id;
          this.approvedAdmin = agreement.approvedByAdmin;

          this.getComments(this.agreementId);

          const performanceKPA = agreement.employee_agreement_kpa;

          performanceKPA.forEach((kpaType) => {
            if (this.kpaName.includes(kpaType.kpaTypeId)) {
              this.templateKPATypes.filter((detail) => {
                if (detail.id === kpaType.kpaTypeId) {
                  detail.kpas.push({
                    name: kpaType.name,
                    objective: kpaType.objective,
                    kpaId: kpaType.id,
                    kpis: this.getCriteriaData(kpaType.kpis_employee),
                    target_date: this.formatEmployeeDate(kpaType.targetDate),
                    weight: kpaType.weight,
                  });
                }
                return {};
              });
            } else {
              this.templateKPATypes.push({
                name: kpaType.kpa_type.name,
                id: kpaType.kpaTypeId,
                kpas: [
                  {
                    name: kpaType.name,
                    objective: kpaType.objective,
                    kpaId: kpaType.id,
                    kpis: this.getCriteriaData(kpaType.kpis_employee),
                    target_date: this.formatEmployeeDate(kpaType.targetDate),
                    weight: kpaType.weight,
                  },
                ],
              });
              this.kpaName.push(kpaType.kpaTypeId);
            }
            return {};
          });
        });

        this.loadingData = false;
      });
    },
  },

  mounted() {
    this.getEmployeeAgreement();
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;

      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;
    });
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}
</style>
